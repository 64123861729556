import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { useAuth } from '../../contexts/AuthContext';

import userDefaultPhoto from '../../assets/userPhoto.png';
import logoCondomob from '../../assets/condomobLogo.svg';

import api from '../../services/api';

import {
  Container,
  UserData,
  BackButton,
  UserIdContainer,
  UserIdPhoto,
  UserIdEmail,
  ListContainer,
  ListItem,
  ButtonUnitLocatorContainer,
  ButtonUnitLocatorText,
  ButtonUnitLocatorIcon,
  NoItemMessage,
  ModalTextInfo,
} from './styles';

const LoginUnit: React.FC = () => {
  const { userUnits, userCredentials, setUserCondomob, logout } = useAuth();

  const history = useHistory();
  const { t } = useTranslation();

  const loginCondomob = async ({
    condoId,
    unitId,
  }: {
    condoId: string;
    unitId: string;
  }) => {
    return api
      .post(`/acesso/social/login`, null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: userCredentials?.accessToken,
        },
        params: { condominio: condoId, unidade: unitId },
      })
      .then(res => {
        if (res.status === 200) {
          if (res.data.termoUsoAceito) {
            setUserCondomob(res.data);
            localStorage.setItem(
              '@CondomobWeb:userCondomob',
              JSON.stringify(res.data),
            );
            localStorage.setItem(
              '@CondomobWeb:tokenCondomob',
              JSON.stringify({
                token: res.data.token,
                dateRegister: new Date(),
              }),
            );
          } else {
            history.push({
              pathname: '/acceptanceTerm',
              state: { data: res.data },
            });
          }
        }
      })
      .catch(err => {
        console.error('@loginCondomob', err.response.data);
        if (err.response.data.error === 'Firebase token inválido!') {
          localStorage.removeItem('@CondomobWeb:tokenFireBase');
          localStorage.removeItem('@CondomobWeb');

          Swal.fire({
            title: `${t('common.words.oops')}`,
            text: t('loginUnit.expiredCredentials'),
            icon: `error`,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            backdrop: `rgba(0,0,0,0.9)`,
          }).then(res => {
            if (res.isConfirmed) {
              logout();
            }
          });
        } else {
          Swal.fire({
            title: `${t('common.words.oops')}`,
            html: err.response.data.error.replace(/\n/g, '<br />'),
            icon: `error`,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            backdrop: `rgba(0,0,0,0.9)`,
          });
        }
      });
  };

  return (
    <Container className="loginNoMenuDefault">
      {userCredentials !== null && (
        <>
          <UserIdContainer>
            <BackButton
              onClick={() => {
                logout();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </BackButton>

            <UserData>
              <UserIdPhoto
                src={
                  userCredentials?.photoURL === null
                    ? userDefaultPhoto
                    : userCredentials.photoURL
                }
              />
              <UserIdEmail>{userCredentials.providerData[0].email}</UserIdEmail>
            </UserData>
          </UserIdContainer>
          <ListContainer>
            {userUnits?.length === 0 ? (
              <NoItemMessage>
                {t('common.phrases.noItemFoundNewTermSearch')}
              </NoItemMessage>
            ) : (
              userUnits &&
              userUnits.map((unit, index) => {
                return (
                  <ListItem key={index.toString()}>
                    <button
                      type="button"
                      onClick={() => {
                        loginCondomob({
                          condoId: unit.condominio.id,
                          unitId: unit.unidade.id,
                        });
                      }}
                    >
                      <img
                        src={
                          unit.condominio.imgLink !== ''
                            ? unit.condominio.imgLink
                            : logoCondomob
                        }
                        alt={unit.condominio.nome}
                      />
                      <div>
                        <strong>{unit.unidade.identificador}</strong>
                        <p>
                          {unit.condominio.nome} - {unit.condominio.cidade.nome}
                        </p>
                      </div>
                      <FontAwesomeIcon
                        icon={['fas', 'chevron-right']}
                        color="#999"
                        size="1x"
                        style={{ marginLeft: 'auto' }}
                      />
                    </button>
                    <hr />
                  </ListItem>
                );
              })
            )}
          </ListContainer>

          <ButtonUnitLocatorContainer>
            <ButtonUnitLocatorText>
              {t('loginUnit.buttonUnitLocator')}
            </ButtonUnitLocatorText>
            <ButtonUnitLocatorIcon
              onClick={() => {
                history.push('/listunitcity');
              }}
            >
              <FontAwesomeIcon
                icon={['fal', 'plus']}
                size="lg"
                color="#fff"
                style={{ marginLeft: 'auto' }}
              />
            </ButtonUnitLocatorIcon>
          </ButtonUnitLocatorContainer>
        </>
      )}
    </Container>
  );
};

export default LoginUnit;
