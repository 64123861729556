import React, { useEffect } from 'react';

import { differenceInMinutes } from 'date-fns';
import { useAuth } from '../contexts/AuthContext';

import AuthRoutes from './AuthRoutes';
import PrivateRoutes from './PrivateRoutes';

const Routes: React.FC = () => {
  const { userCondomob, setUserCondomob, tokenValidated, setTokenValidated } =
    useAuth();

  let userCondomobString = '';

  const checkTokenValidation = () => {
    const localToken = localStorage.getItem('@CondomobWeb:tokenCondomob');
    const localObject = JSON.parse(localToken || '{}');

    if (Object.keys(localObject).length > 0) {
      if (
        differenceInMinutes(new Date(), new Date(localObject.dateRegister)) >
        60 * 24 // 24 hours
      ) {
        setTokenValidated(false);
        localStorage.removeItem('@CondomobWeb:tokenCondomob');
        localStorage.removeItem('@CondomobWeb:userCondomob');
      } else {
        userCondomobString =
          localStorage.getItem('@CondomobWeb:userCondomob') || '{}';
        setTokenValidated(true);
      }
    }
  };

  useEffect(() => {
    checkTokenValidation();
  }, []);

  useEffect(() => {
    checkTokenValidation();
  }, [userCondomob]);

  // Verify if localStorage has a valid data and set on UserCondomob
  useEffect(() => {
    if (userCondomob !== JSON.parse(userCondomobString || '{}')) {
      setUserCondomob(JSON.parse(userCondomobString || '{}'));
    }
  }, [userCondomobString]);

  if (tokenValidated) {
    return (
      <>
        <PrivateRoutes />
      </>
    );
  }

  return <AuthRoutes />;
};
export default Routes;
