import {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode,
} from 'react';

import { getAuth } from 'firebase/auth';
import firebase from 'firebase/firebase-app';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { onSignIn, onSignOut } from '../services/auth';

interface UserDataProps {
  children: ReactNode;
}

interface AuthContextData {
  userCredentials: firebase.User | null;
  userUnits: Array<any> | null;
  userCondomob: any;
  setUserUnits: any | null;
  setUserCondomob: any;
  logout: () => any;
  loading: boolean;
  tokenValidated: boolean;
  setTokenValidated: any;
  setAdminLinkIntegrationId: any;
  adminLinkIntegrationId: number | any;
}

interface UserCondomobProps {
  token?: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: UserDataProps) => {
  const [userCredentials, setUserCredentials] = useState<firebase.User | null>(
    null,
  );
  const [userUnits, setUserUnits] = useState(null);
  const [userCondomob, setUserCondomob] = useState<UserCondomobProps>(
    {} as UserCondomobProps,
  );
  const [loading, setLoading] = useState(true);
  const [tokenValidated, setTokenValidated] = useState(false);
  const [adminLinkIntegrationId, setAdminLinkIntegrationId] = useState(null);

  const auth = getAuth();
  const { t } = useTranslation();

  // Logout app
  function logout() {
    Swal.fire({
      text: `Deseja realmente sair?`,
      icon: `question`,
      showCancelButton: true,
      confirmButtonText: `${t('common.words.yes')}`,
      confirmButtonColor: `#3c5f8b`,
      cancelButtonText: `${t('common.words.no')}`,
      cancelButtonColor: '#6c757d',
      reverseButtons: true,
      backdrop: `rgba(0,0,0,0.9)`,
    }).then(result => {
      if (result.isConfirmed) {
        localStorage.removeItem('@CondomobWeb:tokenCondomob');
        localStorage.removeItem('@CondomobWeb:userCondomob');
        setTokenValidated(false);
        onSignOut();
        setUserCredentials(null);
        setUserCondomob({});
        setUserUnits(null);
      } else {
        return null;
      }
      return auth.signOut();
    });
  }

  const value = {
    userCredentials,
    userUnits,
    setUserUnits,
    userCondomob,
    setUserCondomob,
    logout,
    loading,
    tokenValidated,
    setTokenValidated,
    setAdminLinkIntegrationId,
    adminLinkIntegrationId,
  };

  useEffect(() => {
    const subscribe = auth.onAuthStateChanged(async user => {
      onSignIn(await user?.getIdToken());
      setUserCredentials(user);
      setLoading(false);
    });

    return subscribe;
  }, []);

  // useEffect(() => {
  //   // console.info('userCondomob', userCondomob);
  //   if (Object.keys(userCondomob).length > 0) {
  //     localStorage.setItem(
  //       '@CondomobWeb:tokenCondomob',
  //       JSON.stringify({ token: userCondomob.token, dateRegister: new Date() }),
  //     );
  //   }
  // }, [userCondomob]);

  return (
    <>
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    </>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthContext, AuthProvider, useAuth };
